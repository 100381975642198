import React from "react";
import ReactDOM from "react-dom";
import "./assets/scss/main.scss";
import "./assets/scss/global.scss";
import Index from "./views/Index";
import WalletChecker from "./views/WalletChecker";
import MintingLayout from "./components/minting/MintingLayout";
import {BrowserRouter, Route, Routes} from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
      <BrowserRouter>
          <Routes>
              <Route path="/minting" element={<MintingLayout/>}/>
              <Route path="/wallet-check" element={<WalletChecker/>}/>
              <Route path="/" element={<Index/>}/>
          </Routes>
      </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
