import Roadmap from "../components/roadmap/Roadmap";
import Team from "../components/team/Team";
import Footer from "../components/footer/Footer";
import WhatIsTI from "../components/what-is-ti/WhatIsTI";
import MissionAndVision from "../components/mission-and-vision/MissionAndVision";
import Value from "../components/value/Value";
import Story from "../components/story/Story";
import Transition from "../components/transition/Transition";
import Header from "../components/header/Header";
import { useRef } from "react";
import { isIOS } from "react-device-detect";

function Index() {
  const container = useRef();

  return (
    <section ref={container}>
      <Header />
      <div id="what-is-section">
          <WhatIsTI />
          {/*<MintingLayout/>*/}

        <MissionAndVision />
          {!isIOS && <Transition instance={1} />}
        <Value />
          {!isIOS && <Transition instance={2} />}
        <Story />
        <Roadmap />

        <Team />
        <Footer />
      </div>
    </section>
  );
}

export default Index;
