import { Carousel } from "3d-react-carousal";
import { Col, Container, Row } from "reactstrap";
import { useRef } from "react";
import ValueText from "./ValueText";

function Value() {
  const valueTextRef = useRef();

  let slides = [
    <img src={require("../../assets/images/value/value1.png")} alt="1" />,
    <img src={require("../../assets/images/value/value2.png")} alt="2" />,
    <img src={require("../../assets/images/value/value3.png")} alt="3" />,
  ];

  const callback = function (index) {
    valueTextRef.current?.setIndex(index);
  };

  return (
    <div className="value">
      <Container>
        <Row className="alignCenter">
          <Col md={6}>
            <ValueText ref={valueTextRef} />
          </Col>
          <Col md={6}>
            <div className="caro">
              <Carousel
                slides={slides}
                autoplay={false}
                interval={5000}
                arrows={true}
                onSlideChange={callback}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Value;
