import {useNavigate} from "react-router-dom";

function Header() {
    const navigate = useNavigate();
  return (
    <div className="header">
      <div className="header-video">
        <img src={require("../../assets/images/Web Home BIG.png")} alt="header" className="d-none d-lg-inline-block" />
        <img src={require("../../assets/images/Web Home Small.png")} alt="header" className="d-inline-block d-lg-none" />
        {/*<div className="wallet-checker-link-container">*/}
        {/*    <img src={require("../../assets/images/header_square_nft.png")} alt="wallet-checker" />*/}
        {/*    <div className="wallet-checker-link-text">*/}
        {/*        <h2>MINT NOW</h2>*/}
        {/*        <h1>PUBLIC MINT OPEN</h1>*/}
        {/*    </div>*/}
        {/*    <button className="wallet-checker-link-button" onClick={() => navigate('/minting')}>Mint Now</button>*/}
        {/*</div>*/}
      </div>
    </div>
  );
}

export default Header;
