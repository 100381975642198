export const sections = [
  {
    title: "Community",
    content: (
      <div className="overflow-auto">
        <p>
          Our community is and will always be our number one priority. Holding a
          Teenage Innovators grants access to exclusive awards, contents,
          adventures, and utilities.
        </p>
        <h1>Teen Fund</h1>
        <p>
          A community fund will be set up for the members. The funds will be
          used for the Teen VC, Teen Studio, and TEENOMICS.
        </p>
        <h1>Community Vote</h1>
        <p>
          The ultimate decision for funds, VC, and more allocation will be made
          based on a community vote.
        </p>
        <h1>The TEEN VC for Holders</h1>
        <p>
          A special TEEN Venture Capital will be available for holders chosen
          after approval within our community vote. This will allow them to
          propose a project and receive funds from community funds if the
          project is approved.
        </p>
      </div>
    ),
  },
  {
    title: "Tokenomics",
    content: (
      <div className="overflow-auto">
        <p>
          Our Tokenomics will start after launch. We take it upon ourselves to
          prove that Teenage Innovators consist of much more than "just jpegs".
        </p>
        <h1>$TEEN</h1>
        <p>
          The $TEEN is not like other utility tokens as it holds real value,
          instead of financial value. Users can collect these tokens by staking
          our NFTs and can use them in a variety of ways　especially “Teen
          Adventure”.
        </p>
        <h1>Teenage DAO</h1>
        <p>
          As you know, we are a community-first Web3 project, so we will use the
          $TEEN Token to　determine our next steps together.
        </p>
        <h1>Usage</h1>
        <p>
          Our $TEEN can be used in a variety of ways. We are especially looking
          forward to announcing the special utility, "$Teen Adventure".
        </p>
      </div>
    ),
  },
  {
    title: "BIG Rewards",
    content: (
      <div className="overflow-auto">
        <p>
            There will be giveaways and monthly NFT Drops for our holders. We want to support and spotlight teenage innovators through community and collection.
        </p>
        <h1>Giveaways and Raffles</h1>
        <p>
            We will be selecting a few projects each month to highlight to our community. We prioritize projects which are diverse, open about their identities, and committed to inclusion.
        </p>
        <h1>Monthly NFT Drops</h1>
        <p>
          We will drop out an amazing art monthly from NFTeenagers and NFTKids.
          This will benefit both NFTeenager and Holder.
        </p>
      </div>
    ),
  },
  {
    title: "NFTeen",
    content: (
      <div className="overflow-auto">
        <p>
          The empowerment of teenagers is why we started Teenage Innovators. We
          have prepared a special utility that benefits the Teenagers and also
          benefits the Holders.
        </p>
        <h1>Rewards</h1>
        <p>
          We will buy NFTeenager and NFT Kids projects and drop these amazing
          arts to our holders. Plus available for purchase at $TEEN…
        </p>
        <h1>The TEEN VC for Teenagers</h1>
        <p>
          This Venture Capital will be available for teenage innovators chosen
          after approval within our DAO. This will allow them to start a
          business, create NFTs, and more.
        </p>
      </div>
    ),
  },
  {
    title: "Special",
    content: (
      <div className="overflow-auto">
        <p>
          Something special is coming. We are very excited to bring these
          wonderful Utilities to everyone.
        </p>
        <h1>Teen Studio</h1>
        <p>
          After our launch, part of our community fund will be used for Teen
          Studio. This is the first very educational and inspiring holder-only
          accessible content in the NFT space.
        </p>
        <h1>$TEEN Adventure</h1>
        <p>
          The $TEEN Adventure will be a new place for our holders to use our
          $TEEN Token. This will benefit both NFTeenager and Holder.
        </p>
      </div>
    ),
  },
];
