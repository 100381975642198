import {Col, Container, Row} from "reactstrap";

import Member from "./member/Member";
import TopMember from "./member/TopMember";

function Team() {

    return (
        <div className="team">
            <Container id="team-section">
                <h1 className="team-title">
                    Meet The Team Behind <span style={{color:'#373e98'}}>Teenage Innovators</span>
                </h1>
                <Row>
                    <Col lg={12}>
                        <TopMember
                            class="team-member"
                            name="Mark - Founder"
                            role=""
                            image="Mark.jpg"

                            descripton1="Teenage Innovators is the brainchild of Mark, a 15-year-old digital artist and marketer from Japan. Mark has always been a naturally curious person. Curiosity killed the cat but for Mark, it helped him start early into the world of digital business. At just 13 years old, he started his own video editing business."
                            descripton2="It wasn’t easy. People judged and criticized him for his age. Little did they know, he was just beginning. At 14, Mark entered the NFT space and Teenage Innovators was born. Today, Mark is changing millions of lives by inspiring teenagers everywhere to follow their entrepreneurial dreams."
                            descripton3="Mark is the proof that you can change the world no matter how old you are and that’s the message he wants to spread all over the world, one teenager at a time."

                            twitterLink="https://twitter.com/Mark_Teenage"
                            linkedInLink=""
                            instagramLink=""
                            websiteLink=""
                        />
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} md={4}>
                        <Member
                            class="team-member"
                            name="Empress"
                            role="Community Manager"
                            image="Empress.png"
                            twitterLink="https://twitter.com/empressnft"
                            linkedInLink=""
                            instagramLink=""
                            websiteLink=""
                        />
                    </Col>
                    <Col lg={4} md={4}>
                        <Member
                            class="team-member"
                            name="Anis Brachemi"
                            role="Web Developer"
                            image="Anis.jpg"
                            twitterLink=""
                            linkedInLink="https://www.linkedin.com/in/anis-brachemi/"
                            instagramLink=""
                            websiteLink="https://novem.dev/"
                        />
                    </Col>
                    <Col lg={4} md={4}>
                        <Member
                            class="team-member"
                            name="Saoud Messaoudi"
                            role="Web Developer"
                            image="Saoud.JPG"
                            twitterLink=""
                            linkedInLink="https://www.linkedin.com/in/saoudmessaoudi/"
                            instagramLink=""
                            websiteLink="https://novem.dev/"
                        />
                    </Col>
                    <Col lg={4} md={4}>
                        <Member
                            class="team-member"
                            name="Kassem El-Zoghbi"
                            role="Web Developer"
                            image="Kassem.png"
                            twitterLink="https://twitter.com/booboo_dev"
                            linkedInLink="https://www.linkedin.com/in/kassem-el-zoghbi-61a7741bb/"
                            instagramLink=""
                            websiteLink="https://novem.dev/"
                        />
                    </Col>
                    <Col lg={4} md={4}>
                        <Member
                            class="team-member"
                            name="Vlad Herescu"
                            role="Copywriter"
                            image="Vlad_Herescu.jpg"
                            twitterLink=""
                            linkedInLink=""
                            instagramLink="Fhttps://www.instagram.com/vladlionelherescu/"
                            websiteLink=""
                        />
                    </Col>
                    <Col lg={4} md={4}>
                        <Member
                            class="team-member"
                            name="1BusyGuy"
                            role="Advisor"
                            image="1BusyGuy.jpg"
                            twitterLink="https://twitter.com/1busyguy"
                            linkedInLink=""
                            instagramLink=""
                            websiteLink=""
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Team