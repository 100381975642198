import React, { useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container } from "reactstrap";
import { sections } from "./sections";

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
      <div
          className={className}
          style={{ ...style, display: "block", right: "20px" }}
          onClick={onClick}
      >
        <img src={require('../../assets/images/roadmap/arrow.png')} width={50} height={50} alt="next_arrow"
        style={{marginLeft: "20px", marginTop: "-15px", filter: 'brightness(10)'}}/>
      </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
      <div
          className={className}
          style={{ ...style, display: "block", left: "20px", zIndex: "2" }}
          onClick={onClick}
      >
        <img src={require('../../assets/images/roadmap/arrow.png')} width={50} height={50} alt="prev_arrow"
             style={{marginLeft: "-50px", marginTop: "-15px", transform: "scaleX(-1)", filter: 'brightness(10)'}}/>
      </div>
  );
}

export const MobileRoadmap = () => {
  const sliderRef = useRef();
  const [slideIndex, setSlideIndex] = useState(0);
  const [updateCount, setUpdateCount] = useState(0);
  var settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 1,
    initialSlide: slideIndex,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: () => setUpdateCount(updateCount + 1),
    beforeChange: (current, next) => setSlideIndex(next),
    vertical: false,
    draggable: true,
    autoplay: false,
    adaptiveHeight: false,
    lazyLoad: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };

  return (
    <Container className="mobileSlider" fluid="md">
      <div className="">
        <Slider ref={sliderRef} {...settings}>
          <div className="sliderItem">
            <p>Community</p>
          </div>
          <div className="sliderItem">
            <p>Tokenomics</p>
          </div>
          <div className="sliderItem">
            <p>BIG Rewards</p>
          </div>
          <div className="sliderItem">
            <p>NFTeen</p>
          </div>
          <div className="sliderItem">
            <p>Special</p>
          </div>
        </Slider>
      </div>
      <div className="col-md-6 offset-lg-1 col-12 col-center">
        <div className="card">{sections[slideIndex].content}</div>
      </div>
    </Container>
  );
};
