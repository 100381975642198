import {forwardRef, useImperativeHandle, useState} from "react";

const ValueText = forwardRef((props, ref) => {

    const [selectedValue, setSelectedValue] = useState(0);

    useImperativeHandle(ref, () => ({
        setIndex: (index) => setSelectedValue(index)
    }));

    let values = [
        <div>
            <h1>Community First. Always</h1>
            <p>Our community is and will always be our number one priority. It is the start and the end of this
                beautiful thing we have created, and we won’t let anything happen to it because, without it, we are
                nothing.</p>
        </div>,
        <div>
            <h1>An Unrelenting Support for Teenage Innovators</h1>
            <p>The empowerment of teenagers is why we started Teenage Innovators. We believe teenage crypto artists &
                entrepreneurs are the future and we will do everything to support them to make a mark in the world.<br/><br/>
                That’s why this project isn’t only meant for young talent, but also for those looking to support the new
                up-and-coming wave of entrepreneurs. This is your chance to shape the business landscape of tomorrow!
            </p>
        </div>,
        <div>
            <h1>Think Big. Think Long Term.</h1>
            <p>The best thing about Teenage Innovators is that it is a mission-driven project. We will continue to work
                hard through DAOs with everyone in the community until we achieve our mission. Thinking long-term is
                what differentiates us from other NFT projects.</p>
        </div>
    ]

    return values[selectedValue];
})

export default ValueText