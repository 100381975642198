import { useRef } from "react";
import WalletCheckHeader from "../components/header/WalletCheckHeader";

function WalletChecker() {
  const container = useRef();

  return (
    <section ref={container}>
      <WalletCheckHeader />
    </section>
  );
}

export default WalletChecker;
