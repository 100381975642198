import { useState } from "react";
import Slider from "./Slider";
import { Container, Row } from "reactstrap";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { MobileRoadmap } from "./MobileRoadmap";
import { sections } from "./sections";

function Roadmap() {
  const [selected, setSelected] = useState(0);
  const images = [
    {
      url: require("../../assets/images/roadmap/roadmap1.png"),
    },
    {
      url: require("../../assets/images/roadmap/roadmap6.png"),
    },
    {
      url: require("../../assets/images/roadmap/roadmap3.png"),
    },
    {
      url: require("../../assets/images/roadmap/roadmap4.png"),
    },
    {
      url: require("../../assets/images/roadmap/roadmap5.png"),
    },
  ];

  const particlesInit = async (main) => {
    await loadFull(main);
  };

  const particlesLoaded = () => {};

  return (
    <div>
      <div className="roadmap">
        <h1
          style={{
            fontWeight: "bold",
            color: "white",
            alignSelf: "center",
            fontSize: "60px",
          }}
        >
          Roadmap
        </h1>

        <Particles
          id="tsparticles"
          preset={{
            preset: "stars",
          }}
          options={{
            fullScreen: {
              enable: false,
            },
            particles: {
              color: {
                value: "#ffffff",
              },
              move: {
                direction: "none",
                enable: true,
                outModes: {
                  default: "bounce",
                },
                random: false,
                speed: 1,
                straight: false,
              },
              number: {
                density: {
                  enable: true,
                  area: 800,
                },
                value: 30,
              },
              opacity: {
                value: { min: 0.1, max: 0.4 },
              },
              size: {
                value: { min: 1, max: 5 },
              },
            },
          }}
          init={particlesInit}
          loaded={particlesLoaded}
        />
        <img className="roadmap-bg" src={images[selected].url} alt="" />
        <div className="desktopRoadmap">
          <Container style={{ margin: "auto" }}>
            <Row>
              <div
                className="col-md-6 col-lg-5 col-12"
                style={{ alignSelf: "center" }}
              >
                <Slider
                  items={sections}
                  selected={selected}
                  onSelected={setSelected}
                />
              </div>
              <div className="col-md-6 offset-lg-1 col-12 col-center">
                <div className="card">{sections[selected].content}</div>
              </div>
            </Row>
          </Container>
        </div>
        <div className="mobileRoadmap">
          <MobileRoadmap />
        </div>
      </div>
    </div>
  );
}

export default Roadmap;
