import {useEffect} from "react";

function Transition(props) {

    useEffect(() => {
        const items = document.querySelectorAll(".timeline li");

        function isElementInViewport(el) {
            const rect = el.getBoundingClientRect();
            return (
                rect.top >= -100 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) + 100 &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        }

        function callbackFunc() {
            for (let i = 0; i < items.length; i++) {
                if (isElementInViewport(items[i])) {
                    if(!items[i].classList.contains("in-view")){
                        items[i].classList.add("in-view");
                    }
                } else if(items[i].classList.contains("in-view")) {
                    items[i].classList.remove("in-view");
                }
            }
        }

        window.addEventListener("load", callbackFunc);
        window.addEventListener("scroll", callbackFunc);
    },[])

    return (
        <section className="transition">
                        {props.instance===1?
                            <div className="first angle one"/> :
                            <div className="first angle two"/>
                        }
                        {props.instance===1?
                            <div className="angle three"/> :
                            <div className="angle four"/>
                        }
                        {props.instance===1?
                            <div className="last angle five"/> :
                            <div className="last angle six"/>
                        }
        </section>
    );
}

export default Transition