function Slider({ items, selected, onSelected }) {
  return (
    <div
      className="slider noselect"
      style={{
        transform: `translate(0px, ${
          selected < 3 ? -selected * 104 : (5 - selected) * 104
        }px)`,
      }}
    >
      <div
        className={`${selected === 3 ? "farthest" : "hidden"}`}
        onClick={() => onSelected(1)}
      >
        {items[1].title}
      </div>
      <div
        className={`${
          selected === 3 ? "closest" : selected === 4 ? "farthest" : "hidden"
        }`}
        onClick={() => onSelected(2)}
      >
        {items[2].title}
      </div>

      <div
        className={`${
          selected === 1 || selected === 2
            ? "hidden"
            : selected === 0
            ? "farthest"
            : selected === 4
            ? "closest"
            : ""
        }`}
        onClick={() => onSelected(3)}
      >
        {items[3].title}
      </div>
      <div
        className={`${
          selected === 2
            ? "hidden"
            : selected === 1
            ? "farthest"
            : selected === 0 || selected === 3
            ? "closest"
            : ""
        }`}
        onClick={() => onSelected(4)}
      >
        {items[4].title}
      </div>
      <div
        className={`${
          selected === 1 || selected === 4
            ? "closest"
            : selected === 2 || selected === 3
            ? "farthest"
            : ""
        }`}
        onClick={() => onSelected(0)}
      >
        {items[0].title}
      </div>
      <div
        className={`${
          selected === 3
            ? "hidden"
            : selected === 4
            ? "farthest"
            : selected === 0 || selected === 2
            ? "closest"
            : ""
        }`}
        onClick={() => onSelected(1)}
      >
        {items[1].title}
      </div>
      <div
        className={`${
          selected === 3 || selected === 4
            ? "hidden"
            : selected === 0
            ? "farthest"
            : selected === 1
            ? "closest"
            : ""
        }`}
        onClick={() => onSelected(2)}
      >
        {items[2].title}
      </div>

      <div
        className={`${
          selected === 1 ? "farthest" : selected === 2 ? "closest" : "hidden"
        }`}
        onClick={() => onSelected(3)}
      >
        {items[3].title}
      </div>
      <div
        className={`${selected === 2 ? "farthest" : "hidden"}`}
        onClick={() => onSelected(4)}
      >
        {items[4].title}
      </div>
    </div>
  );
}

export default Slider;
