import {Input} from "reactstrap";
import {useState} from "react";
import WalletChecker from "../../utils/walletChecker";

function WalletCheckHeader() {
    const [walletAddress, setWalletAddress] = useState("");
    const [isWalletAddressValid, setIsWalletAddressValid] = useState(false);

    const [message, setMessage] = useState("");
    const [messageColor, setMessageColor] = useState("text-white");


    const onInputChange = (e) => {
        setWalletAddress(e.target.value);
        setIsWalletAddressValid(e.target.value.length === 42);
    }

    const onCheckClick = () => {
        if (!isWalletAddressValid) {
            setMessage("Please enter a valid wallet address");
            setMessageColor("text-danger");
            return;
        }
        if(WalletChecker(walletAddress)) {
            setMessage("You are teenlisted!");
            setMessageColor("text-success");
        } else {
            setMessage("You are not teenlisted! Please check again in a few days.");
            setMessageColor("text-danger");
        }
    }

    return (
        <div className="header">
            <div className="header-video">
                <img src={require("../../assets/images/Web Home BIG.png")} alt="header"
                     className="d-none d-lg-inline-block"/>
                <img src={require("../../assets/images/Web Home Small.png")} alt="header"
                     className="d-inline-block d-lg-none"/>
                <div className="wallet-checker-container">
                    <h2 className="text-white">Check if you are teenlisted</h2>
                    <Input type="text" onChange={onInputChange} placeholder="Enter your wallet address"/>
                    <button className="wallet-checker-button" onClick={onCheckClick}>CHECK
                    </button>
                    <p className={messageColor}>{message}</p>
                </div>
            </div>
        </div>
    );
}

export default WalletCheckHeader;
